import React from "react";
import { ReactComponent as EmailIcon } from "../assets/images/icons/email.svg";
import { ReactComponent as CallIcon } from "../assets/images/icons/call.svg";
import { ReactComponent as RightTopArrowIcon } from "../assets/images/icons/rightTopArrow.svg";
import { ReactComponent as TickInCircleIcon } from "../assets/images/icons/tickInCircle.svg";

import { ReactComponent as FacebookIcon } from "../assets/images/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../assets/images/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../assets/images/icons/linkedin.svg";
import { ReactComponent as PinterestIcon } from "../assets/images/icons/pinterest.svg";
import { ReactComponent as GlobeIcon } from "../assets/images/icons/globe.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { ReactComponent as MapIcon } from "../assets/images/icons/map.svg";
import { ReactComponent as LocationIcon } from "../assets/images/icons/location.svg";
import { ReactComponent as MonitorSearchIcon } from "../assets/images/icons/monitorSearch.svg";
import { ReactComponent as AddressIcon } from "../assets/images/icons/address.svg";
import { ReactComponent as QouteIcon } from "../assets/images/icons/qoute.svg";
import { ReactComponent as CallCenterIcon } from "../assets/images/icons/call_center.svg";
import { ReactComponent as HubIcon } from "../assets/images/icons/hub.svg";
import { ReactComponent as HeadOficeIcon } from "../assets/images/icons/head_office.svg";
import { ReactComponent as StickerIcon } from "../assets/images/icons/sticker.svg";
import { ReactComponent as PlanIcon } from "../assets/images/icons/plan.svg";


import { ReactComponent as CustomerBookingIcon } from "../assets/images/icons/CustomerBooking.svg";
import { ReactComponent as HubIconIcon } from "../assets/images/icons/HubIcon.svg";
import { ReactComponent as HeadOfficeIcon } from "../assets/images/icons/HeadOffice.svg";
import { ReactComponent as StickerIconIcon } from "../assets/images/icons/StickerIcon.svg";
import { ReactComponent as CustomizableIcon } from "../assets/images/icons/Customizable.svg";
import { ReactComponent as CashBookingIcon } from "../assets/images/icons/CashBooking.svg";
import { ReactComponent as AccountBookingIcon } from "../assets/images/icons/AccountBooking.svg";
import { ReactComponent as AccountPartyIcon } from "../assets/images/icons/AccountParty.svg";
import { ReactComponent as DeliveryRunSheetIcon } from "../assets/images/icons/DeliveryRunSheet.svg";
import { ReactComponent as SecureDeliveryIcon } from "../assets/images/icons/SecureDelivery.svg";
import { ReactComponent as BillingIcon } from "../assets/images/icons/Billing.svg";
import { ReactComponent as MobileAppsIcon } from "../assets/images/icons/MobileApps.svg";

interface IconProps {
  icon: string; //make the clear type to make switch
  className?: string;
  color?: string;
  width?: number;
}

const SVGIcon = ({
  icon,
  color = "#696b71",
  width = 14,
  className,
}: IconProps) => {
  // In this case you have to think about the switch and types in typescript.
  const Icons: any = {
    email: (
      <EmailIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    phone: (
      <CallIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    rightTopArrow: (
      <RightTopArrowIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    tickInCircle: (
      <TickInCircleIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    facebook: (
      <FacebookIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    twitter: (
      <TwitterIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    linkedin: (
      <LinkedinIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Pinterest: (
      <PinterestIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    globe: (
      <GlobeIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    search: (
      <SearchIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    map: (
      <MapIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    location: (
      <LocationIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    monitorSearch: (
      <MonitorSearchIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    address: (
      <AddressIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    qoute: (
      <QouteIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    call_center: (
      <CallCenterIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    hub: (
      <HubIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    head_office: (
      <HeadOficeIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    sticker: (
      <StickerIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    plan_icon: (
      <PlanIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    CustomerBooking: (
      <CustomerBookingIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Hub: (
      <HubIconIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    HeadOffice: (
      <HeadOfficeIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Sticker: (
      <StickerIconIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Customizable : (
      <CustomizableIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    CashBooking: (
      <CashBookingIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    AccountBooking: (
      <AccountBookingIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    AccountParty: (
      <AccountPartyIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    DeliveryRunSheet: (
      <DeliveryRunSheetIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    SecureDelivery: (
      <SecureDeliveryIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Billing: (
      <BillingIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    MobileApps: (
      <MobileAppsIcon
        // fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
  };
  return Icons[icon];
};

export default SVGIcon;
