import React, { useState } from "react";
import { Button, Modal } from "antd";
import gallery from "../../../../assets/images/gallery.png";
import delivery from "../../../../assets/images/delivery.png";
import { ReactComponent as PlayIcon } from "../../../../assets/images/play.svg";

interface MyComponent {
  sectionData: any;
}
const Company: React.FC<MyComponent> = ({ sectionData }) => {
  const [tabData, setTabData] = useState<any>(sectionData[0]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="about_company_section">
        <div className="container">
          <div className="about_inner">
            <div className="left_side">
              <div className="image_top">
                <img src={gallery} alt="Customer & client" title="Lastmileplus Customer & client" />
              </div>
              <div className="image_bottom">
                <div className="video_play_button">
                  <Button type="primary" onClick={showModal}>
                    <PlayIcon />
                  </Button>
                </div>
                <img src={delivery} alt="courier boy" title="Lastmileplus Courier Boy" />
              </div>
              <div className="counter">
                <h2>20+</h2>
                <p>Years of Experience</p>
              </div>
            </div>
            <div className="right_side">
              <div className="heading">
                <h2>
                  <span>About Lastmileplus</span>
                  Streamline Logistic Processes to Boost Your Business
                </h2>
              </div>
              <p>
                Our 360-degree courier management system can handle complex
                processes and facilitate you to bring speed, accuracy, and
                security to the entire delivery process. It brings automation
                and assists you in simplifying all the tasks.
              </p>
              <div className="tab_section">
                <ul>
                  {sectionData?.map((item: any) => {
                    return (
                      <li key={item.id}>
                        <Button
                          className={item.id === tabData.id ? "active" : ""}
                          onClick={() => setTabData(item)}
                          type="primary"
                        >
                          {item.button}
                        </Button>
                      </li>
                    );
                  })}
                </ul>

                <div className="tab_content">
                  {tabData ? (
                    <div className="descreption">
                      <div
                        dangerouslySetInnerHTML={{ __html: tabData.content }}
                      ></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="video_modal"
        title={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={"800px"}
        centered
      >
        <iframe
									width="100%"
									height="100%"
									src={`https://www.youtube.com/embed/$?autoplay=1&mute=1`}
									title="YouTube Video"
									// frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								></iframe>
      </Modal>
    </>
  );
};

export default Company;
