import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Logo from "./elements/Logo";
import MenuStrip from "./elements/MenuStrip";

import HeaderJson from "./header.json";
import DemoModal from "./elements/DemoModal";

const HeaderView: React.FC = () => {
  const [activeHamburger, setActiveHamburger] = useState<boolean>(false);
  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleMenuOpen = () => {
    setActiveHamburger(!activeHamburger);
  };
  const closeDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout.Header
      className={`mainHeader 
      ${activeHamburger === true && "menuOpen"} 
      ${isScrolled === true && "fixed"}`}
    >
      <div className="container">
        <Logo />
        <div className="headerNavSection">
          <MenuStrip
            navigation={HeaderJson.menu}
            demoLink={HeaderJson.demo_link}
            closeDemoModal={closeDemoModal}
            handleMenuOpen={handleMenuOpen}
          />

          <div
            className={activeHamburger === true ? "is-active" : ""}
            id="hamburgerIcon"
            onClick={handleMenuOpen}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={closeDemoModal}
      />
    </Layout.Header>
  );
};

export default HeaderView;
