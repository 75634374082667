import React from "react";
import { Col, Row } from "antd";
import { AnimationOnScroll } from "react-animation-on-scroll";

import ContentSection from "./ContentSection";

import EllipseLeft from "../../../../../assets/images/Ellipse-1.svg";
import EllipseRight from "../../../../../assets/images/Ellipse-2.svg";
import DotsRight from "../../../../../assets/images/dots.svg";
import LaptopImg from "../../../../../assets/images/laptopImg1.png";
const About: React.FC = () => {
  return (
    <section id="About">
      <img src={EllipseLeft} alt="Background Circle media" title="Background Circle Image" className="EllipseLeft" />
      <AnimationOnScroll
        animateOnce
        animateIn="animate__bounceInLeft"
        offset={100}
      >
        <img src={LaptopImg} alt="Courier and Logistic Management Software" title="Optimize courier and logistics operations with advanced software" className="LaptopImg" />
      </AnimationOnScroll>
      <div className="container">
        <Row gutter={60} align={"bottom"}>
          <Col
            xs={24}
            lg={{ span: 12, offset: 12 }}
            xl={{ span: 12, offset: 10 }}
          >
            <ContentSection />
          </Col>
        </Row>
      </div>
      <img src={EllipseRight} alt="Background Circle" title="Background Circle" className="EllipseRight" />
      <img src={DotsRight} alt="Background Dot media" title="Background Dot Image" className="DotsRight" />
    </section>
  );
};
export default About;
