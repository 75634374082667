import React, { useRef } from "react";
import smital from "../../../../assets/images/smital.png";
import hardik from "../../../../assets/images/hardik.png";
import umang from "../../../../assets/images/umang.png";
import Slider from "react-slick";
import { ReactComponent as NextIcon } from "../../../../assets/images/sliderNextArrow.svg";

interface MyComponent {
  sectionData: any;
}
const Leader: React.FC<MyComponent> = ({ sectionData }) => {
  const profile = [smital, hardik, umang];
  const slider = useRef<any>(null);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="leader">
      <div className="content_part">
        <div className="heading">
          <h2>
            <span>Our Team</span>
            Brains Behind Lastmileplus
          </h2>
        </div>
        <p>
          Our forward-looking leaders have considered the complexity and
          challenges of courier delivery services in line with diverse business
          requirements. Lastmileplus is a robust result of their smart yet
          dedicated efforts.
        </p>
        <div className="btn_wrap">
          <NextIcon
            className="icon prev"
            onClick={() => slider?.current?.slickPrev()}
          />
          <NextIcon
            className="icon next"
            onClick={() => slider?.current?.slickNext()}
          />
        </div>
      </div>
      <div className="leader_main_block">
        <Slider ref={slider} {...settings}>
          {sectionData?.map((item: any, index: number) => {
            return (
              <div className="leader_block" key={index}>
                <div className="image_block">
                  <img src={profile[item.id - 1]} alt={item.imagealt} title={item.imagetitle} />
                </div>
                <div className="content">
                  <p>{item.postion}</p>
                  <h3>{item.name}</h3>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Leader;
