import React, { useState } from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { HashLink } from "react-router-hash-link";

interface MyComponent {
  menu: any;
}
const FooterServiceSection: React.FC<MyComponent> = ({ menu }) => {
  const [activeMenu, setActiveMenu] = useState<string>("");

  return (
    <div className="serviceSection">
      <h4 className="footerTitle">Quick Links</h4>
      <ul className="footerMenu">
        {menu?.map((item: any, index: number) => {
          return (
            <li key={index}>
              {/* <Link to={item.link}>
              <SVGIcon icon="rightTopArrow" />
                {item.name}
              </Link> */}
              <HashLink
                smooth
                key={index}
                to={item.link}
                title={item.name}
                onClick={() => {
                  setActiveMenu(item.name);
                }}
                className={item.name === activeMenu ? "active" : ""}
              >
                <SVGIcon icon="rightTopArrow" />
                {item.name}
              </HashLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterServiceSection;
