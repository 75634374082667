import { Link } from "react-router-dom";

const ContentSection: React.FC = () => {
  return (
    <div className="contentSection">
      <strong className="titleTag">Why choose us</strong>
      <h3 className="titleSection">
        Streamline Logistic Processes to Boost Your Business
      </h3>
      <p>
        Our 360-degree courier management system can handle complex processes
        and facilitate you to bring speed, accuracy, and security to the entire
        delivery process. As one of the most advanced courier and logistics
        solutions, Lastmile+ offers
      </p>

      <ul className="checkList">
        <li>Streamlined and Seamless Courier Delivery Services</li>
        <li>Delightful and Personalized Experience for All Customers</li>
        <li>Scalability for Operations as per the Growth of Business</li>
        <li>Increased Efficiency with Real-time Updates on All Orders</li>
      </ul>

      <Link to={"/contact-us"} className="buttonLink primaryColor">
      CONTACT US
      </Link>
    </div>
  );
};
export default ContentSection;
