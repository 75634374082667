import React, { useEffect } from "react";
import { BrowserRouter, } from "react-router-dom";
import Router from "./config/RouterConfig";
import { handleStorageEvents } from "./config/Global";
import "animate.css/animate.min.css";
import HelmetComponent from "./views/SeoTitle/HelmetComponent";
import ScrollToTop from "./views/SeoTitle/ScrollToTop";

const App: React.FC = () => {


  
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvents);
  }, []);
  useEffect(() => {

  }, []);
  return (
    <BrowserRouter>
     <ScrollToTop />
      <HelmetComponent />
      <Router />
    </BrowserRouter>
  );
};

export default App;
