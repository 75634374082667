import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

interface MyComponent {
  navigation: any;
  demoLink: any;
  closeDemoModal: () => void;
  handleMenuOpen: () => void;
}

const MenuStrip: React.FC<MyComponent> = ({
  navigation,
  demoLink,
  closeDemoModal,
  handleMenuOpen,
}) => {
  const location = useLocation();

  return (
    <div className="menuStrip">
      <div className="nav">
        {navigation?.map((item: any, index: number) => {
          return (
            <HashLink
              smooth
              key={index}
              to={item.link}
              title={item.name}
              className={`${
                "#" + item.name === location.hash ||
                item.link === location.pathname
                  ? "active"
                  : ""
              } `}
            >
              {item.name}
            </HashLink>
          );
        })}
      </div>

      <div className="actionBtns">
        {demoLink && (
          <Link
            to={demoLink.link}
            className="buttonPrimary"
            onClick={() => {
              closeDemoModal();
              handleMenuOpen();
            }}
          >
            {demoLink.name}
          </Link>
        )}
      </div>
    </div>
  );
};
export default MenuStrip;
