import React from "react";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../../config/Constant";

import { ReactComponent as SiteLogo } from "../../../../assets/images/logo.svg";
const Logo: React.FC = () => {
  return (
    <Link
      to={CONSTANT.PAGE_LINK.HOME}
      className="siteLogo"
      title="Last Mile Plus"
    >
      <SiteLogo className="img" />
    </Link>
  );
};
export default Logo;
