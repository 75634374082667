import { Col, Row } from "antd";
import React from "react";
import specialinnerbanner from "../../../../assets/images/blog.png"

const Innerbanner: React.FC = () => {
  return (
    <section className="inner_banner">
        <div className="container">
        <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <h1>Blog</h1>
                </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <img src={specialinnerbanner} alt="Banner" title="Lastmileplus Banner" />
            </Col>
        </Row>
        </div>
    </section>
  );
};

export default Innerbanner;
