import React, { useEffect } from "react";
import Innerbanner from "./components/Innerbanner";
import "../../../assets/styles/modules/contact.less";
import Specialofferform from "./components/Specialofferform";

const SpecialOffer: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Innerbanner />
      <Specialofferform />
    </>
  );
};

export default SpecialOffer;
