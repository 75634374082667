import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../views/layouts/MainLayout";
import PageNotFound from "../views/errors/PageNotFound";
import Home from "../views/modules/Home";
import Contact from "../views/modules/Contact";
import SpecialOffer from "../views/modules/SpecialOffer";
import About from "../views/modules/About";
import Features from "../views/modules/Features";
import Blog from "../views/modules/Blog";
import BlogDetails from "../views/modules/Blog/components/BlogDetails";
// import Prcing from "../views/modules/Prcing";

export default function Router() {
  return useRoutes(RouterConfig);
}

export const RouterConfig = [
  // {
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "/login",
  //       element: <Login />,
  //     },
  //     {
  //       path: "/forgot-password",
  //       element: <ForgotPassword />,
  //     },
  //     {
  //       path: "/reset/:otp",
  //       element: <ResetPassword />,
  //     },
  //   ],
  // },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/features",
        element: <Features />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:id",
        element: <BlogDetails />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      // {
      //   path: "/pricing",
      //   element: <Prcing />,
      // },
      {
        path: "/special-offer",
        element: <SpecialOffer />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
