import React from "react";
import { Layout } from "antd";
// import useStore from "../../../store";
import { Outlet } from "react-router-dom";
import FooterView from "./FooterView";
import HeaderView from "./HeaderView";

const MainLayout: React.FC = () => {
  return (
    <Layout className={`mainWrapper`}>
      <HeaderView />
      <Layout.Content className="mainContent">
        <Outlet />
      </Layout.Content>
      <FooterView />
    </Layout>
  );
};

export default MainLayout;
