import React from "react";
import indus from "../../../../assets/images/logo/indus.png";
import meesho from "../../../../assets/images/logo/meesho.png";
import sunskine from "../../../../assets/images/logo/sunskine.png";
import nirvasa from "../../../../assets/images/logo/nirvasa.png";
import shree from "../../../../assets/images/logo/shree.png";
import arani from "../../../../assets/images/logo/arani.png";
import zatki from "../../../../assets/images/logo/zatki.png";

interface MyComponent {
  sectionData: any;
}

const Clients: React.FC<MyComponent> = ({ sectionData }) => {
  const logo = [indus, meesho, sunskine, nirvasa, shree, nirvasa, arani, zatki];

  return (
    <section className="logo_main_section">
      <div className="container">
        <div className="heading">
          <h2>
            <span>Clients</span>
            our trusted partners
          </h2>
        </div>
      </div>
      <div className="logo_main_content">
        <div className="container">
          <div className="logo_main_block">
            {sectionData?.map((item: any) => {
              return (
                <div className="logo_img_block" key={item.id}>
                  <img
                    src={logo[item.id - 1]}
                    alt={item.imagealt}
                    title={item.imagetitle}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
