import React from "react";
import { Link } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import right from "../../../../assets/images/right.png";

import CustomerBookingandTracking from "../../../../assets/images/CustomerBookingandTracking.svg";
import HubandBranchManagement from "../../../../assets/images/HubandBranchManagement.svg";
import HeadOfficeManagement from "../../../../assets/images/HeadOfficeManagement.svg";
import StickerBarcodesforShipping from "../../../../assets/images/StickerBarcodesforShipping.svg";
import CustomizableRateCard from "../../../../assets/images/CustomizableRateCard.svg";
import CashBooking from "../../../../assets/images/CashBooking.svg";

interface MyComponent {
  sectionData: any;
}

const WhatWeOffer: React.FC<MyComponent> = ({ sectionData }) => {
  const icon = [CustomerBookingandTracking, HubandBranchManagement, HeadOfficeManagement, StickerBarcodesforShipping, CustomizableRateCard, CashBooking];

  return (
    <section className="what_we_offer_section">
        <img src={left} alt="left" className="left common" />
        <img src={right} alt="right" className="right common" />

      <div className="container">
        <div className="heading">
          <h2>
            <span>Why Lastmileplus</span>
            Your Trusted Courier Management System for Better Services 
          </h2>
        </div>
        <div className="about_main_block">
          {sectionData?.map((item: any) => {
            return (
              <div className="about_item" key={item.id}>
                <div className="icon">
                  <h3>
                    <span>
                      <img src={icon[item.id - 1]} alt="logos" />
                    </span>
                    {item.title}
                  </h3>
                </div>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
        <div className="read_more_button">
          <Link to="/About">View All</Link>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
