import React from "react";
import blogone from "../../../../assets/images/blogone.jpg";
import blogtwo from "../../../../assets/images/customerbooking.jpg";
import blogthree from "../../../../assets/images/blogthree.jpg";

interface MyComponent {
  sectionData: any;
}
const RecentPost: React.FC<MyComponent> = ({ sectionData }) => {
  const blogo_image = [blogone, blogtwo, blogthree];

  return (
    <div className="recent_post_block">
      <h3 className="heading">Recent Post</h3>
      <div className="blog_post">
        {sectionData?.map((item: any) => {
          return (
            <div className="blogo_list">
              <div className="image">
                <img src={blogo_image[item.id - 1]} alt="logos" />
              </div>
              <div className="content">
                <h4 className="title">{item.title}</h4>
                {item.date}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentPost;
