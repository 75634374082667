import React, { useEffect } from "react";

import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Button, Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/app";
import { doContactSubmit } from "./ContactSlice";
import { assignErrorToInput } from "../../../../store/api";
import { ContactRequest } from "./Validation";
import { Notification } from "../../../../config/Global";
import { randomnumbersubstring } from "../../../../utils/commonFunctions";

const FormComponents: React.FC = () => {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = (data: any) => {
    const payload = `full_name=${data.full_name}&email=${data.email}&subject=${data.subject}&message=${data.message}`;

    dispatch(doContactSubmit(payload))
      .then((res: any) => {
        console.log("data form", res?.data);

        res?.data?.NOTIFICATION.map((err: string) =>
          Notification.success({
            message: err,
          })
        );
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.NOTIFICATION);
      });
  };

  const resetCaptcha = () => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  return (
    <FormBox form={form} onFinish={handleSubmit}>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <InputBox.Text
            required
            placeholder="Your Name"
            // label="Your Name"
            name="full_name"
            rules={ContactRequest().full_name("Your Name")}
          />
          <InputBox.Text
            required
            // label="Your Email"
            placeholder="Your Email"
            name="email"
            rules={ContactRequest().email("Email")}
          />
          <InputBox.Text
            required
            // label="Subject"
            placeholder="Subject"
            name="subject"
            rules={ContactRequest().subject("Subject")}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="textraria_form">
          <InputBox.TextArea
            placeholder="Your Message"
            required
            // label="Your Massage"
            name="message"
            rules={ContactRequest().message("Your Massage")}
          />
        </Col>
      </Row>
      <Row gutter={15} className="">
        <Col xs={8}>
          <div className="captchaLeft">
            <InputBox.Text
              // label="Captcha"
              name="captcha"
              className="captchaCode"
              disabled
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            <Button
              type="link"
              className="resetCaptcha autoWidth autoHeight"
              onClick={() => resetCaptcha()}
            >
              {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              id="reset-icon"
            >
              <path
                d="M24 40q-6.65 0-11.325-4.675Q8 30.65 8 24q0-6.65 4.675-11.325Q17.35 8 24 8q4.25 0 7.45 1.725T37 14.45V8h3v12.7H27.3v-3h8.4q-1.9-3-4.85-4.85Q27.9 11 24 11q-5.45 0-9.225 3.775Q11 18.55 11 24q0 5.45 3.775 9.225Q18.55 37 24 37q4.15 0 7.6-2.375 3.45-2.375 4.8-6.275h3.1q-1.45 5.25-5.75 8.45Q29.45 40 24 40Z"
                fill="#cc0000"
              />
            </svg> */}
              <svg
                className="resetCaptcha"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                id="reset-icon"
              >
                {/* <use xlinkHref={`${SVGSprite}#reset-icon`}></use> */}
                <path d="M24 40q-6.65 0-11.325-4.675Q8 30.65 8 24q0-6.65 4.675-11.325Q17.35 8 24 8q4.25 0 7.45 1.725T37 14.45V8h3v12.7H27.3v-3h8.4q-1.9-3-4.85-4.85Q27.9 11 24 11q-5.45 0-9.225 3.775Q11 18.55 11 24q0 5.45 3.775 9.225Q18.55 37 24 37q4.15 0 7.6-2.375 3.45-2.375 4.8-6.275h3.1q-1.45 5.25-5.75 8.45Q29.45 40 24 40Z" />
              </svg>
            </Button>
          </div>
        </Col>

        <Col xs={8}>
          <div className="input_group">
            <InputBox.Text
              className="captch"
              placeholder="Capcha"
              // label="Enter Captcha"
              name="re_captcha"
              required
              // onPaste={(e) => {
              //   e.preventDefault();
              //   return false;
              // }}
              rules={ContactRequest().re_captcha("Captcha")}
            />
          </div>
        </Col>
        <Col xs={8}>
          <Button type="primary" htmlType="submit" className="submit_button">
            Submit
          </Button>
        </Col>
      </Row>
    </FormBox>
  );
};

export default FormComponents;
