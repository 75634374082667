import React from "react";
import FormComponents from "../utils/FormComponents";

const Contactform: React.FC = () => {
  return (
    <section className="contact_form">
        <div className="container">
        <div className="heading">
            <h2>
                <span>Contact Us</span>
                How Can We Help
                </h2>
        </div>
    <FormComponents />
        </div>
    </section>
  );
};

export default Contactform;
