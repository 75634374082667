import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo1 from "../../../../assets/images/logos/logo1.png";
import Logo2 from "../../../../assets/images/logos/logo2.png";
import Logo3 from "../../../../assets/images/logos/logo3.png";
import Logo4 from "../../../../assets/images/logos/logo4.png";
import Logo5 from "../../../../assets/images/logos/logo5.png";
import Logo6 from "../../../../assets/images/logos/logo6.png";
import Logo7 from "../../../../assets/images/logos/logo7.png";

const LogoSlider: React.FC = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 200,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    rows: 2,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section id="LogoSlider">
      <div className="container text-center">
        <strong className="titleTag">PARTNERS</strong>
        <h3 className="titleSection">WHO PUT TRUST ON US</h3>
        <Slider {...settings} className="logoList mt-40">
          <img className="logoItem" src={Logo1} alt="" />
          <img className="logoItem" src={Logo2} alt="" />
          <img className="logoItem" src={Logo3} alt="" />
          <img className="logoItem" src={Logo4} alt="" />
          <img className="logoItem" src={Logo5} alt="" />
          <img className="logoItem" src={Logo6} alt="" />
          <img className="logoItem" src={Logo7} alt="" />
          <img className="logoItem" src={Logo1} alt="" />
          <img className="logoItem" src={Logo2} alt="" />
          <img className="logoItem" src={Logo3} alt="" />
          <img className="logoItem" src={Logo4} alt="" />
          <img className="logoItem" src={Logo5} alt="" />
          <img className="logoItem" src={Logo6} alt="" />
          <img className="logoItem" src={Logo7} alt="" />
          <img className="logoItem" src={Logo1} alt="" />
          <img className="logoItem" src={Logo2} alt="" />
          <img className="logoItem" src={Logo3} alt="" />
          <img className="logoItem" src={Logo4} alt="" />
          <img className="logoItem" src={Logo5} alt="" />
          <img className="logoItem" src={Logo6} alt="" />
        </Slider>
      </div>
    </section>
  );
};
export default LogoSlider;
