import React from "react";
import moment from "moment";

const Copyright: React.FC = () => {
  return (
    <div className="copyright">
      <div className="container">
        Copyright &copy; {moment().format("Y")} Last Mile Plus
      </div>
    </div>
  );
};

export default Copyright;
