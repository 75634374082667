import React from "react";
import AboutJson from "./about.json";
import "../../../assets/styles/modules/about.less";
import Innerbanner from "./components/Innerbanner";
import CounterSection from "../../../components/Sections/CounterSection";
import WhatWeOffer from "./components/WhatWeOffer";
import OurHistory from "./components/OurHistory";
import Client from "./components/Clients";
import Leader from "./components/Leader";
import Company from "./components/Company";

const About: React.FC = () => {
  const { what_we_offer  } = AboutJson;
  const { our_history  } = AboutJson;
  const { logo  } = AboutJson;
  const { leader  } = AboutJson;
  const { about_descreption  } = AboutJson;
  
  return (
   <>
   <Innerbanner />
   <Company sectionData={about_descreption} />
   <CounterSection />
   <WhatWeOffer sectionData={what_we_offer} />
   <Leader  sectionData={leader} />
   <OurHistory sectionData={our_history} />
   <Client sectionData={logo} />
   </>
  );
};

export default About;
