import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as BannerImg } from "../../../../assets/images/banner_img_1.svg";
import BannerImgRight from "../../../../assets/images/banner_img_right_1.png";
import BannerImgLeft from "../../../../assets/images/banner_img_left_1.png";
import DemoModal from "../../../layouts/MainLayout/elements/DemoModal";

interface MyComponent {
  bannerData: any;
}

const Banner: React.FC<MyComponent> = ({ bannerData }) => {
  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

  const { mainHeadingOne, mainHeadingTwo, mainHeadingThree, content, button } =
    bannerData;

  const handleDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };

  return (
    <>
      <section id="banner">
        <img src={BannerImgRight} alt="" className="bannerImgRight" />

        <div className="container">
          <Row gutter={20} align={"middle"}>
            <Col
              xs={{ span: 24, order: 2 }}
              md={{ span: 14, order: 1 }}
              lg={12}
            >
              <div className="contentSection">
                <strong className="mainHeadingOne">{mainHeadingOne}</strong>
                <h1 className="mainHeadingTwo">{mainHeadingTwo}</h1>
                <h1 className="mainHeadingThree">{mainHeadingThree}</h1>
                <p>{content}</p>

                {button?.map((item: any) => {
                  return item.id === "demo" ? (
                    <Button
                      key={item.id}
                      className="buttonLink"
                      onClick={() => {
                        handleDemoModal();
                      }}
                    >
                      {item.name}
                    </Button>
                  ) : (
                    <Link key={item.id} to={item.link} className="buttonLink">
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 10, order: 2 }}
              lg={12}
            >
              <div className="imageSection">
                <BannerImg className="bannerImg" />
              </div>
            </Col>
          </Row>
        </div>

        <img src={BannerImgLeft} alt="" className="bannerImgLeft" />
      </section>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={handleDemoModal}
      />
    </>
  );
};
export default Banner;
