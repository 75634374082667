import React from "react";
import { useLocation } from "react-router-dom";
import PostData from "../PostData.json";
interface MyComponent {
  sectionData: any;
}
const PopularTags: React.FC<MyComponent> = () => {
  const location = useLocation();

  const lastSlashIndex = location.pathname.lastIndexOf("/");
  const lastPart =
    lastSlashIndex !== -1
      ? location.pathname.substring(lastSlashIndex + 1)
      : "";

  const sectionData = PostData.PostData.find(
    (item: any) => item.ur === lastPart
  );

  return (
    <div className="recent_post_block">
      <h3 className="heading">Popular Tags</h3>
      <ul>
        {sectionData?.tag?.map((item: any) => {
          return <li key={item.id}>{item.tagname}</li>;
        })}
      </ul>
    </div>
  );
};

export default PopularTags;
