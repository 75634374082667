import React from "react";

interface MyComponent {
  sectionData: any;
}

const OurHistory: React.FC<MyComponent> = ({ sectionData }) => {

  return (
    <section className="the_story">
      <div className="container">
        <div className="heading">
          <h2>
            <span>OUR History</span>
            The Story of LastMile Plus
          </h2>
        </div>
        <div className="histiry_main_block">

        {sectionData?.map((item: any) => {
     return (
      <div className="history_item_main"  key={item.id}>
        <div className="year">{item.year}</div>
          <div className="history_item">
                {item.icon}
              <h3>{item.title}</h3>
            <p>
            {item.content}
            </p>
          </div>
          </div>
               );
            })}
        </div>
      </div>
    </section>
  );
};

export default OurHistory;
