import React from "react";
// import { Link } from "react-router-dom";
import { ReactComponent as BoxImage } from "../../../../assets/images/box.svg";
import left from "../../../../assets/images/left.png";
import right from "../../../../assets/images/right.png";

import CustomerBookingandTracking from "../../../../assets/images/CustomerBookingandTracking.svg";
import HubandBranchManagement from "../../../../assets/images/HubandBranchManagement.svg";
import HeadOfficeManagement from "../../../../assets/images/HeadOfficeManagement.svg";
import StickerBarcodesforShipping from "../../../../assets/images/StickerBarcodesforShipping.svg";

import CustomizableRateCard from "../../../../assets/images/CustomizableRateCard.svg";
import CashBooking from "../../../../assets/images/CashBooking.svg";
import AccountBooking from "../../../../assets/images/AccountBooking.svg";
import AccountPartyManagement from "../../../../assets/images/AccountPartyManagement.svg";

import DRSDeliveryRunSheet from "../../../../assets/images/DRSDeliveryRunSheet.svg";
import SecureDeliveryOptions from "../../../../assets/images/SecureDeliveryOptions.svg";
import Billing from "../../../../assets/images/Billing.svg";
import MobileAppsforSmartDelivery from "../../../../assets/images/MobileAppsforSmartDelivery.svg";

interface MyComponent {
  sectionData: any;
}

const Blog: React.FC<MyComponent> = ({ sectionData }) => {
  const icon = [
    CustomerBookingandTracking,
    HubandBranchManagement,
    HeadOfficeManagement,
    StickerBarcodesforShipping,
    CustomizableRateCard,
    CashBooking,
    AccountBooking,
    AccountPartyManagement,
    DRSDeliveryRunSheet,
    SecureDeliveryOptions,
    Billing,
    MobileAppsforSmartDelivery,
  ];

  return (
    <section className="features_section">
      <div className="container">
        <div className="top_section">
          <div className="left_side">
            <div className="heading">
              <h2>
                <span>KEY FEATURES</span>
                Get Profit-boosting Benefits For Your Business
              </h2>
            </div>
          </div>
          <div className="right_side">
            <p>
              Lastmileplus is a user-friendly and advanced courier management
              system with excellent features to meet shipping and
              delivery-related requirements effectively.
            </p>
          </div>
        </div>
      </div>
      <div className="features_blog_main">
        <div className="features_blog">
          <img src={left} alt="left" className="left common" />
          <img src={right} alt="right" className="right common" />
          <div className="container">
            <div className="features_blog_inner">
              {sectionData?.map((item: any) => {
                return (
                  <div className="blog">
                    <div className="icon">
                      <BoxImage />
                      <span>
                        <img src={icon[item.id - 1]} alt={item.imagealt} title={item.imagetitle} />
                      </span>
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="read_more_button">
          <Link to="/About">View All</Link>
        </div> */}
    </section>
  );
};

export default Blog;
