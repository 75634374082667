import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";

import { ReactComponent as FooterLogo } from "../../../../assets/images/FooterLogo.svg";

interface MyComponent {
  summary: string;
  socialLink: any;
}
const FooterFirstSection: React.FC<MyComponent> = ({ summary, socialLink }) => {
  return (
    <div className="firstSection">
      <FooterLogo className="FooterLogo" />
      <p className="summary">{summary}</p>
      <ul className="social">
        {socialLink?.map((item: any, index: number) => {
          return (
            <li key={index}>
              <a href={item.link} target="_blank" title={item.name}  rel="noreferrer">
                <SVGIcon
                  icon={item.name}
                  className={item.name === "twitter" ? "stroke" : ""}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterFirstSection;
