import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";

interface MyComponent {
  address: any;
}
const FooterContactSection: React.FC<MyComponent> = ({ address }) => {
  const addressText = address && address[0];
  const emailText = address && address[1];
  const telText = address && address[2];

  return (
    <div className="contactSection">
      <ul className="contact">
        <li>
          <SVGIcon
            icon={addressText.name}
            className={`icon ${addressText.name}`}
          />
          <p><a href={`https://www.google.com/maps/place/LastMile+Plus/@23.0093125,72.5068041,15z/data=!4m6!3m5!1s0x395e9b72baca68ff:0x61b8798e04b62a75!8m2!3d23.0093125!4d72.5068041!16s%2Fg%2F11y1kwm9ss?entry=ttu`} target="_blank" rel="noreferrer">{addressText.text}</a></p>
        </li>

        <li>
          <SVGIcon icon={telText.name} className={`icon ${telText.name}`} />
          <a href={`tel:${telText.link}`} target="_blank" rel="noreferrer">
            <span>{telText.title}</span>
            <strong>{telText.text}</strong>
          </a>
        </li>
        <li>
          <SVGIcon icon={emailText.name} className={`icon ${emailText.name}`} />
          <a href={`mailto:${emailText.link}`} target="_blank" rel="noreferrer">
            <span>{emailText.title}</span>
            <strong>{emailText.text}</strong>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterContactSection;
