import React from "react";
import { Link } from "react-router-dom";
import blogone from "../../../../assets/images/blogone.jpg";
import customerbooking from "../../../../assets/images/customerbooking.jpg";
import blogthree from "../../../../assets/images/blogthree.jpg";
import blogfour from "../../../../assets/images/blogfour.jpg";
import blogfive from "../../../../assets/images/blogfive.jpg";
import featureimage from "../../../../assets/images/featureimage.jpg";
import navigatinglast from "../../../../assets/images/navigatinglast.jpg";
import blogsix from "../../../../assets/images/blogsix.png";
import transformingdelivery from "../../../../assets/images/transformingdelivery.png";
import lastmiledeliverymanagement from "../../../../assets/images/lastmiledeliverymanagement.jpg";
import roleofcouriermanagementsysteminlogisticsbusiness from "../../../../assets/images/roleofcouriermanagementsysteminlogisticsbusiness.jpg";

interface MyComponent {
  sectionData: any;
}

const Post: React.FC<MyComponent> = ({ sectionData }) => {
  const blogo_image = [
    blogone,
    customerbooking,
    blogthree,
    blogfour,
    blogfive,
    blogsix,
    featureimage,
    navigatinglast,
    transformingdelivery,
    lastmiledeliverymanagement,
    roleofcouriermanagementsysteminlogisticsbusiness
  ];

  return (
    <section className="blog_post_section">
      <div className="container">
        <div className="blog_inner">
          {/* {sectionData?.map((item: any) => { */}
          {sectionData.reverse().map((item: any) => {
            return (
              <div className="blog" key={item.id}>
                <div className="left_side">
                  <img
                    src={blogo_image[item.id - 1]}
                    alt={item.imagealt}
                    title={item.imagetitle}
                  />
                </div>
                <div className="right_side">
                  <span className="date">{item.date}</span>
                  <h3 className="title">
                    <Link to={item.ur}>{item.title}</Link>
                  </h3>
                  <p className="content">{item.descreption}</p>

                  <div className="button">
                    <Link to={item.ur}>{item.link}</Link>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="read_more_button">
          <Link to="/">View All</Link>
        </div> */}
        </div>
      </div>
    </section>
  );
};

export default Post;
