import React from 'react'
import Innerbanner from './components/Innerbanner'
import Post from './components/Post'
import Postjson from "./PostData.json";
import "../../../assets/styles/modules/Blog.less";

const Blog: React.FC = () => {
    const { PostData } = Postjson;
    return (
    <>
    <Innerbanner />
    <Post sectionData={PostData} />
    </>
  )
}

export default Blog
