import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';
import SeoTitle from "./SeoTitles.json"

const HelmetComponent = () => {
    const [seoDetails, setSeoDetails] = useState<any>();
    const location = useLocation();
    useEffect (() => {
        const metatags = SeoTitle.title.find((item:any) => item.slug === location.pathname )    
        setSeoDetails(metatags)
    },[location])
    // console.log("seoDetails",seoDetails);
    
    
   
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{seoDetails?.metaTitle }</title>
                <meta
                    name="description"
                    content={seoDetails?.metaDescription}
                />
                <link rel="canonical" href={window.location.href} />
                {/* <meta name="keywords" content={keywords ?? "energy, offshore, wind industry"} />
                <link rel="canonical" href={url ??"/"} />
                <meta property="og:title" content={title??"Energy Career Navigator"} />
                <meta property="og:url" content={url ?? "/"} />
                <meta property="og:image" content={image} />
                <meta
                    property="og:site_name"
                    content="Energy Career Navigator"
                />
                <meta property="og:type" content="website" /> */}
            </Helmet>
        </>
    );
}

export default HelmetComponent