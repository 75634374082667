import React, { useEffect } from "react";
import Innerbanner from "./components/Innerbanner";
import "../../../assets/styles/modules/contact.less";
import Contactform from "./components/Contactform";

const Contact: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Innerbanner />
      <Contactform />
    </>
  );
};

export default Contact;
