import React, { useEffect } from "react";
import Banner from "./components/Banner";
import HomeJson from "./home.json";

import LogoSlider from "./components/LogoSlider";
import About from "./components/About";
import Features from "../../../components/Sections/Features";
import Testimonial from "../../../components/Sections/Testimonial";
import "../../../assets/styles/modules/home.less";
import CounterSection from "../../../components/Sections/CounterSection";
import PlanSection from "../../../components/Sections/PlanSection";
const Home: React.FC = () => {
  const { banner } = HomeJson;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Banner bannerData={banner} />
      <Features />
      <About />
      <Testimonial />
      <CounterSection />
      <PlanSection />
      <LogoSlider />
    </>
  );
};

export default Home;
