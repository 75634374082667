import CountUp from "react-countup";
import { Progress } from "antd";
import "../../assets/styles/commonSections/counterSection.less";

const CounterSection: React.FC = () => {
  const counterData = [
    {
      id: 1,
      number: 50,
      max_number: 50,
      title: "Kilotonnes CO2 Reduction",
      color: "#FB8249",
    },
    {
      id: 2,
      number: 100,
      max_number: 90,
      title: "Businesses Benefited Globally",
      color: "#3858DD",
    },
    {
      id: 3,
      number: 150,
      max_number: 80,
      title: "Dedicated Resources",
      color: "#44B4B7",
    },
    {
      id: 4,
      number: 10,
      max_number: 50,
      title: "Million Deliveries",
      color: "#2F3EA3",
    },
  ];

  return (
    <div id="counterSection">
      <div className="container">
        <div className="boxes">
          {counterData?.map((conuter: any) => {
            return (
              <div key={conuter.id} className={`counter ${conuter.className}`}>
                <CountUp
                  duration={5}
                  end={conuter.number}
                  separator=","
                  suffix="+"
                  className="countUp"
                />
                <strong className="title" style={{ color: `${conuter.color}` }}>
                  {conuter.title}
                </strong>
                <Progress
                  percent={conuter.max_number}
                  showInfo={false}
                  className="counterProgress"
                  style={{ color: `${conuter.color}` }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default CounterSection;
