import React, { useEffect, useState } from "react";

import MapBg from "../../assets/images/map_bg.png";
import Slider from "react-slick";

import "../../assets/styles/commonSections/testimonial.less";
import SVGIcon from "../../utils/SVGIcon";
import { ReactComponent as SliderArrow } from "../../assets/images/sliderNextArrow.svg";
import EllipseLeft from "../../assets/images/Ellipse-3.svg";

const SlickArrowLeft = ({ currentslide, ...props }: any) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentslide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentslide === 0 ? true : false}
    type="button"
  >
    <SliderArrow />
  </button>
);
const SlickArrowRight = ({ currentslide, slidecount, ...props }: any) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentslide === slidecount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentslide === slidecount - 1 ? true : false}
    type="button"
  >
    <SliderArrow />
  </button>
);

const Testimonial: React.FC = () => {
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  const [slider1, setSlider1] = useState<any>(null);
  const [slider2, setSlider2] = useState<any>(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    // fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "100px",
  };

  const slidesData = [
    {
      id: 1,
      name: "Melissa Meckwan",
      post: "Customer",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    },
    {
      id: 2,
      name: "Taylor Meckwan",
      post: "Customer",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    },
    {
      id: 3,
      name: "Melissa Taylor",
      post: "Customer",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    },
  ];

  return (
    <section id="Testimonial">
      <img
        src={EllipseLeft}
        alt="Background media"
        title="Background Image"
        className="EllipseLeft"
      />
      <div
        className="sliderContainer"
        style={{ backgroundImage: `url(${MapBg})` }}
      >
        <strong className="titleTag">TESTIMONIALS</strong>
        <h3 className="titleSection">Whats Our Clients Say!</h3>
        <div className="thumbnailSliderWrap">
          <SVGIcon icon="qoute" className="qoute" />
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {slidesData.map((slide) => (
              <div className="slideBox" key={slide.id}>
                <img
                  alt=""
                  className="slick-slide-image"
                  src={`https://source.unsplash.com/random/300×300/?person=${slide.id}`}
                />
              </div>
            ))}
          </Slider>
        </div>
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {slidesData.map((slide) => (
            <div className="testimonialItem" key={slide.id}>
              <p>{slide.content}</p>
              <h4 className="name">{slide.name}</h4>
              <strong className="post">{slide.post}</strong>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
export default Testimonial;
