import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Set scroll behavior to smooth
    document.documentElement.style.scrollBehavior = "smooth";
    
    // Scroll to the top
    window.scrollTo(0, 0);

    // Reset scroll behavior to auto after the scroll
    const scrollTimeout = setTimeout(() => {
      document.documentElement.style.scrollBehavior = "auto";
    }, 1000); // Adjust the timeout value as needed

    return () => clearTimeout(scrollTimeout);
  }, [pathname]);

  return null;
}
