import React from "react";
import "../../../assets/styles/modules/features.less";
import Blog from "./Components/Blog";
import Blogjson from "./blog.json";
import Innerbanner from "./Components/Innerbanner";

const Features = () => {
  const { features_blog } = Blogjson;

  return (
    <>
      <Innerbanner />
      <Blog sectionData={features_blog} />
    </>
  );
};

export default Features;
