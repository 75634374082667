import React, { useRef } from "react";
import Slider from "react-slick";
import { Col, Row } from "antd";
import SVGIcon from "../../utils/SVGIcon";
import SystemModuleHoverImg from "../../assets/images/SystemModuleHoverImg.png";
import "../../assets/styles/commonSections/features.less";
import FeaturesJson from "./Features.json";
import { ReactComponent as NextIcon } from "../../assets/images/sliderNextArrow.svg";

const Features: React.FC = () => {
  const slider = useRef<any>(null);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 1000,
    // cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const listItem = (
    listTitle: string,
    listDetails: string,
    icon: string,
    index: number
  ) => (
    <div className="moduleItem" key={index}>
      <div className="icon">
        <SVGIcon icon={icon ?? "call_center"} />
      </div>
      <strong className="listTitle">{listTitle}</strong>
      <p className="listDetails">{listDetails}</p>
      <img src={SystemModuleHoverImg} alt="Background media" title="Background image" className="systemModuleHoverImg" />
    </div>
  );

  return (
    <section id="Features">
      <div className="container">
        <Row align={"middle"}>
          <Col xs={24} lg={16}>
            <strong className="titleTag primaryColor">FEATURES</strong>
            <h2 className="titleSection">Features Lastmileplus Offers</h2>
            <p className="sumarrySection">
            As a robust and reliable system, Lastmile+ offers many profit-boosting benefits to your courier or logistic business including-
            </p>
          </Col>
          <Col xs={24} lg={8}>
            <div className="btn_wrap">
              <NextIcon
                className="icon prev"
                onClick={() => slider?.current?.slickPrev()}
              />
              <NextIcon
                className="icon next"
                onClick={() => slider?.current?.slickNext()}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Slider ref={slider} {...settings} className="moduleList">
        {FeaturesJson?.map((item: any, index: number) => {
          return listItem(item.title, item.description, item.icon, index);
        })}
      </Slider>
      {/* <div className="text-center mt-40">
        <Link to={"#"} className="buttonLink">
          View All
        </Link>
      </div> */}
    </section>
  );
};
export default Features;
