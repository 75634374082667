import React from "react";
import { useParams } from "react-router-dom";
import Postjson from "../PostData.json";
import Innerbanner from "../components/Innerbanner";
import blogone from "../../../../assets/images/blogone.jpg";
import customerbooking from "../../../../assets/images/customerbooking.jpg";
import blogthree from "../../../../assets/images/blogthree.jpg";
import blogfour from "../../../../assets/images/blogfour.jpg";
import blogfive from "../../../../assets/images/blogfive.jpg";
import featureimage from "../../../../assets/images/featureimage.jpg";
import navigatinglast from "../../../../assets/images/navigatinglast.jpg";
import blogsix from "../../../../assets/images/blogsix.png";
import transformingdelivery from "../../../../assets/images/transformingdelivery.png";
import lastmiledeliverymanagement from "../../../../assets/images/lastmiledeliverymanagement.jpg";
import roleofcouriermanagementsysteminlogisticsbusiness from "../../../../assets/images/roleofcouriermanagementsysteminlogisticsbusiness.jpg";
import PopularTags from "./PopularTags";
import RecentPost from "./RecentPost";

const BlogDetails = () => {
  const { id } = useParams();
  const { Recentdeta } = Postjson;
  const { PostData } = Postjson;
  const filerData: any = Postjson.PostData.find((item: any) => item.ur === id);
  const blogo_image = [
    blogone,
    customerbooking,
    blogthree,
    blogfour,
    blogfive,
    blogsix,
    featureimage,
    navigatinglast,
    transformingdelivery,
    lastmiledeliverymanagement,
    roleofcouriermanagementsysteminlogisticsbusiness
  ];



  return (
    <>  
      <Innerbanner />
      <section className="blog_post_section">
        <div className="container">
          <div className="blog_inner">
            <div className="descreption_side">
              <div className="image_block">
                <img src={blogo_image[filerData.id - 1]} alt={filerData.imagealt} title={filerData.imagetitle} />
              </div>
              <div className="content_block">
                <span className="date">{filerData.date}</span>
                <h1 className="title">{filerData.title}</h1>
                <div
                            dangerouslySetInnerHTML={{
                              __html: filerData.content,
                            }}
                          ></div>
              
              </div>
            </div>
            <div className="right_asidebar">
              <RecentPost sectionData={Recentdeta} />
              <PopularTags sectionData={PostData} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
