import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

interface MyComponent {
  product_features: any;
}
const FooterProductFeatures: React.FC<MyComponent> = ({ product_features }) => {
  return (
    <div className="serviceSection product_features">
      <h4 className="footerTitle">Product Features</h4>
      <Row gutter={[20, 0]} align={"top"}>
        <Col xs={24} md={12}>
          <ul className="footerMenu">
            {product_features?.slice(0, 4)?.map((item: any, index: number) => {
              return (
                <li key={index}>
                  <Link to={item.link}>
                    <SVGIcon icon="rightTopArrow" />
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Col>
        <Col xs={24} md={12}>
          <ul className="footerMenu">
            {product_features?.slice(4, 8)?.map((item: any, index: number) => {
              return (
                <li key={index}>
                  <Link to={item.link}>
                    <SVGIcon icon="rightTopArrow" />
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default FooterProductFeatures;
