import React from "react";
import "../../assets/styles/commonSections/planSection.less";
import SVGIcon from "../../utils/SVGIcon";

const PlanSection: React.FC = () => {
  const planData = [
    {
      id: 1,
      name: "Basic Plan",
      period: "month",
      price: 30,
      list: [
        { id: 1, content: "Customer Booking" },
        { id: 2, content: "Customer Tracking" },
        { id: 3, content: "Hub & Branch Mgt" },
        { id: 4, content: "Mobile App for Delivery" },
      ],
      link: "contact-us",
    },
    {
      id: 3,
      name: "Standard Plan",
      period: "month",
      price: 50,
      list: [
        { id: 1, content: "Everything in Basic" },
        { id: 2, content: "Stickers & Barcodes" },
        { id: 3, content: "Customisable Rate Card" },
        { id: 4, content: "Shared Support Team" },
      ],
      link: "contact-us",
    },
    {
      id: 2,
      name: "Enterprise Plan",
      period: "month",
      price: 90,
      list: [
        { id: 1, content: "Everything in Standard" },
        { id: 2, content: "Corporate Booking" },
        { id: 3, content: "Corporate Auto Billing" },
        { id: 4, content: "API Integration" },
        { id: 5, content: "Dedicated Support" },
      ],
      link: "contact-us",
    },
  ];
  return (
    <div id="Pricing">
      <div className="container text-center">
        <strong className="titleTag">SELECT YOUR PLAN</strong>
        <h3 className="titleSection">Which is Your Most Suitable Option?</h3>
        <p className="sumarrySection text-center span-50">
          Lastmile+ is a unique and user-friendly courier management solution
          with cost-effective packages and seamless performance. Have a glimpse
          on our plans.
        </p>
        <div className="planListing">
          {planData?.map((plan: any) => {
            return (
              <div key={plan.id} className="box">
                <div>
                  <div className="icon">
                    <SVGIcon icon="plan_icon" />
                  </div>
                  <h4 className="name">{plan.name}</h4>
                  <div className="rate">
                    <strong>{plan.price}$</strong> <span>/</span>{" "}
                    <span>{plan.period}</span>
                  </div>
                  <hr />
                  <ul>
                    {plan.list.map((list: any) => {
                      return <li key={list.id}>{list.content}</li>;
                    })}
                  </ul>
                </div>
                <div>
                  <a
                    href={plan.link}
                    target="_blank"
                    rel="noreferrer"
                    className="buttonLink primaryColor"
                  >
                    Get the Plan
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default PlanSection;
