import React from "react";
import { Col, Layout, Row } from "antd";
import Copyright from "./elements/Copyright";
import FooterJson from "./footer.json";

import FooterFirstSection from "./elements/FooterFirstSection";
import FooterServiceSection from "./elements/FooterServiceSection";

import FooterBuilding from "../../../assets/images/footerBg.svg";
import FooterContactSection from "./elements/FooterContactSection";
import FooterNewsUpdatesSection from "./elements/FooterNewsUpdatesSection";
import FooterProductFeatures from "./elements/FooterProductFeatures";

const FooterView: React.FC = () => {
  const {
    summary,
    social_media,
    address,
    menu,
    news_section,
    product_features,
  } = FooterJson;
  return (
    <>
      <Layout.Footer className="mainFooter">
        <div className="container">
          <FooterNewsUpdatesSection news_section={news_section} />
          <Row gutter={[20, 30]} align={"top"}>
            <Col xs={24}>
              <FooterContactSection address={address} />
            </Col>
            <Col xs={{ span: 24, order: 3 }} lg={{ span: 6, order: 1 }}>
              <FooterFirstSection socialLink={social_media} summary={summary} />
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={10}
              lg={{ span: 4, offset: 1, order: 2 }}
            >
              <FooterServiceSection menu={menu} />
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              md={14}
              lg={{ span: 12, offset: 1, order: 3 }}
            >
              <FooterProductFeatures product_features={product_features} />
            </Col>
          </Row>
          <Copyright />
        </div>
        <div
          style={{ backgroundImage: `url(${FooterBuilding})` }}
          className="bgImg"
        ></div>
      </Layout.Footer>
    </>
  );
};

export default FooterView;
